'use strict';

(function($){

    var app = {
        // Initial vars
        width: 768,

        init: function() {
            this.inputMD();
            this.dropdownMenu();
            this.scrollEffect();
            this.addressmapOpen();
            this.searchboxOpen();
            this.fixedNav();
            this.parallax();
            this.slickResize();
            this.slickSlider();
            this.sliderHome();
            this.homeCollapse();
            this.maquinasCollapse();
            //this.formValidate();
            this.chart();
            this.lazyframe();
            this.modalOffer();
            //this.newsletterForm();
            this.cookiesManagement();

            //newletter validation form
            /*$('#form-newsletter-footer').submit(function(e){
               e.preventDefault();
               if ($(this).isValid()){
                   var datos = $(this).serialize();
                       $.post(news_url, datos, function(data){
                           console.log("datos: "+datos);
                           if(data=='ok')
                           {
                               // console.log("success");
                            //    console.log(news_gracias);
                               $('#form-newsletter-footer #nf-nombre').val("");
                               $('#form-newsletter-footer #nf-email').val("");
                               $('#form-newsletter-footer #nf-aviso_legal').val("");
                               $("#form-newsletter-footer").prepend("<div class=\"alert alert-success form-message\">"+news_gracias+"</div>");
								// ga('send', 'event', 'Contact', 'Sent', 'newsletter - '+current_url);
								//tra ga('send', 'event', 'Contactos por Id', 'Contacto Enviado', data);

                           }
                           else
                           {
                               $('#form-newsletter-footer #nf-nombre').val("");
                               $('#form-newsletter-footer #nf-email').val("");
                               $('#form-newsletter-footer #nf-aviso_legal').val("");
                               $("#form-newsletter-footer").prepend("<div class=\"alert alert-danger form-message\">"+news_error+"</div>");
                           }
                       });
               }
            });*/

            return false;
        },
        formValidate: function() {
            $.validate({
                modules: 'security'
             });
        },
        chart: function() {
          var chartData = [
            [6.16, 6.40, 5.48, 4.83, 4.95, 4.82],
            [0.84, 0.62, 1.03, 0.85, 0.80, 0.51],
            [76.04, 184.00, 122.10, 121.05, 157.08, 112.41],
            [2.22, 2.40, 1.93, 2.45, 2.30, 2.03]
          ],
          chartYears = ['2018', '2019', '2020','2021','2022','2023'],
          chart = document.getElementsByClassName('chart');

          function paintChart(el, label, index) {
            var ctx = document.getElementById(el);
            var myChart = new Chart(ctx, {
              type: 'bar',
              data: {
                labels: chartYears,
                datasets: [{
                  label: label,
                  data: index,
                  backgroundColor: [
                    'rgba(0, 114, 180, 0.2)',
                    'rgba(0, 114, 180, 0.2)',
                    'rgba(0, 114, 180, 0.2)',
                    'rgba(0, 114, 180, 0.2)',
                    'rgba(0, 114, 180, 0.2)',
                    'rgba(0, 114, 180, 0.2)'
                  ],
                  borderColor: [
                    'rgba(0, 114, 180, 1)',
                    'rgba(0, 114, 180, 1)',
                    'rgba(0, 114, 180, 1)',
                    'rgba(0, 114, 180, 1)',
                    'rgba(0, 114, 180, 1)',
                    'rgba(0, 114, 180, 1)'
                  ],
                  borderWidth: 1
                }]
              },
              options: {
                legend: {
                  display: false
                },  
                tooltips: {
                  intersect: false
                },            
                responsive: true,
                scales: {
                  yAxes: [{
                    ticks: {
                      beginAtZero: true
                    }
                  }]
                }
              }
            });
          }

          if (chart.length !== 0) {
            for(i = 0; i < chartData.length; i++) {
              paintChart('bar-chart-' + i, chartLabel[i], chartData[i]);
            }
          }
        },
        lazyframe: function () {
          lazyframe('.lazyframe', {
            apikey: 'AIzaSyAY2Q_LyTigjZjumZ45sK_W4uaktyvdxR8', // cambiar esta API por la del proyecto
            debounce: 250,
          }); 
        },
        newsletterForm: function()
        {
            $('#form-newsletter').submit(function (e)
            {
                var msgSuccess = $('#form-newsletter').find('.msg-success');
                var msgError = $('#form-newsletter').find('.msg-error');
                msgSuccess.addClass('hide');
                msgError.addClass('hide');
                e.preventDefault();
                if ($(this).isValid()) {
                    var datos = $(this).serialize();
                    $.post(news_popup_url, datos, function (data) {
                        var arrayData = data.split('__AND__');
                        if(arrayData[0] === 'error')
                        {
                            msgError.removeClass('hide').html(arrayData[1]);
                        }
                        else
                        {
                            $('#form-newsletter').trigger('reset');
                            msgSuccess.removeClass('hide').html(arrayData[1]);
                        }
                    })
                }

                return false;
            })
        },
        modalOffer : function ()
        {
            var selectOferta =  $('select#tn-oferta');
            $('.btn-modal-offer').click(function ()
            {
                selectOferta.selectpicker('val', '');
                if($(this).data('offer') !== 0 && $(this).data('offer') !== '0'){
                    selectOferta.selectpicker('val', $(this).data('offer'));
                }
            })
        },
        ajaxCall: function(url, type, dataType, $dataObj, cache, crossDomain, callback) {
            // Default values if undefined values comes
            if (typeof type === 'undefined') type = 'GET';
            if (typeof dataType === 'undefined') {
                /**
                    "xml": Returns a XML document that can be processed via jQuery.
                    "html": Returns HTML as plain text; included script tags are evaluated when inserted in the DOM.
                    "script": Evaluates the response as JavaScript and returns it as plain text. Disables caching by appending a query string parameter, _=[TIMESTAMP], to the URL unless the cache option is set to true. Note: This will turn POSTs into GETs for remote-domain requests.
                    "json": Evaluates the response as JSON and returns a JavaScript object. Cross-domain "json" requests are converted to "jsonp" unless the request includes jsonp: false in its request options. The JSON data is parsed in a strict manner; any malformed JSON is rejected and a parse error is thrown. As of jQuery 1.9, an empty response is also rejected; the server should return a response of null or {} instead. (See json.org for more information on proper JSON formatting.)
                    "jsonp": Loads in a JSON block using JSONP. Adds an extra "?callback=?" to the end of your URL to specify the callback. Disables caching by appending a query string parameter, "_=[TIMESTAMP]", to the URL unless the cache option is set to true.
                    "text": A plain text string.
                 */
                dataType = 'html';
            }
            if (typeof dataToSend === 'undefined') dataToSend = {};
            if (typeof crossDomain === 'undefined') crossDomain = false;

            /* 99% percent times, is a form. By default serialize */
            if (typeof $dataObj === 'object') {
                dataToSend = $dataObj.serialize();
            }

            $.ajax({
                type: 'POST',
                url: "/",
                data: { data: dataToSend },
                dataType: "html",
                cache: false,
                async: true,
                crossDomain: crossDomain,
                timeout: 5,
                xhr: function()
                {
                    var xhr = new window.XMLHttpRequest();
                    //Upload progress
                    xhr.upload.addEventListener("progress", function(evt){
                        if (evt.lengthComputable) {
                            var percentComplete = evt.loaded / evt.total;
                            //Do something with upload progress
                        }
                    }, false);
                    //Download progress
                    xhr.addEventListener("progress", function(evt){
                        if (evt.lengthComputable) {
                            var percentComplete = evt.loaded / evt.total;
                            //Do something with download progress
                        }
                    }, false);

                    return xhr;
                },
                beforeSend: function (xhr, settings) {
                    // code here
                },
                complete: function (xhr, status) {
                    // code here
                },
                success: function (response) {
                    // code here
                },
                error : function (xhr, textStatus, errorThrown) {
                    // code here
                },
            });
            return true;
        },
        cleanUri: function(str) {
            str = str.replace(/^\s+|\s+$/g, ''); // trim
            str = str.toLowerCase();

            // remove accents, swap ñ for n, etc
            var from = "àáäâèéëêìíïîòóöôùúüûñç·/_,:;";
            var to   = "aaaaeeeeiiiioooouuuunc------";
            for (var i=0, l=from.length ; i<l ; i++) {
                str = str.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i));
            }
            str = str.replace(/[^a-z0-9 -]/g, '').replace(/\s+/g, '-').replace(/-+/g, '-');

            return str;
        },
        dropdownMenu: function() {
            if ($(window).width() > this.width) {
                $('.dropdown').hover(function() {
                  $(this).find('.dropdown-menu').stop(true, true).delay(50).fadeIn();
                }, function() {
                  $(this).find('.dropdown-menu').stop(true, true).delay(50).fadeOut();
                });

                $('.dropdown').removeClass('open');
                $('.dropdown-toggle').removeAttr("data-toggle");
            } else {
                $('.dropdown-menu').removeAttr('style');
                $('.dropdown').off('mouseenter mouseleave');
                //$('.dropdown-toggle').data('toggle', 'dropdown');
                $(".dropdown-toggle").attr("data-toggle", "dropdown");
            }
        },

        addressmapOpen: function() {
            $('.list-countries a.active').click(function() {
                $('.venta-addresses').addClass('addresses-open');
            });
            $('.list-countries a.active').click(function() {
                $('.postventa-addresses').addClass('addresses-open');
            });

          $('.close-adr').click(function() {
              $('.list-addresses').removeClass('addresses-open');
          });
        },

        searchboxOpen: function() {
          $('#search a, #search-mob').click(function() {
              $('#searchbox').toggleClass('searchbox-open');
          });

          $('.close-btn').click(function() {
              $('#searchbox').removeClass('searchbox-open');
          });
        },

        fixedNav: function() {
              // Fixed nav
              /*if ( $(window).width() <= 991 ) {
                  var fixmeTop = $('#mobile-nav').offset().top;
                  $(window).scroll(function() {
                      var currentScroll = $(window).scrollTop();
                      if (currentScroll >= fixmeTop) {
                          $('#mobile-nav').addClass('fixed');
                      } else {
                          $('#mobile-nav').removeClass('fixed');
                      }
                  });
              } else if ( $(window).width() > 991 ) {
                  var fixmeTop = $('#header-main').offset().top;
                  $(window).scroll(function() {
                      var currentScroll = $(window).scrollTop();
                      if (currentScroll >= fixmeTop) {
                          $('#header-main').addClass('fixed');
                          $('header').addClass('has-background');
                      } else {
                          $('#header-main').removeClass('fixed');
                          $('header').removeClass('has-background');
                      }
                  });
              }*/
              if ( $(window).width() > 991 ) {
                var fixmeTop = $('#main-header').offset().top;

                $(window).scroll(function() {
                    var currentScroll = $(window).scrollTop();
                      if (currentScroll >= fixmeTop) {
                          $('#main-header').addClass('fixed');
                          $('body').css({ 'padding-top': '100px' });
                      } else {
                          $('#main-header').removeClass('fixed');
                          $('body').css({ 'padding-top': '0' });
                      }
               });
              }
         },

        smoothScroll: function($obj) {
            var target = $($obj.hash);
            if (target.length) {
                $('html, body').animate({
                    scrollTop: target.offset().top - 50
                }, 1000);
            }
        },

       scrollEffect: function() {
            $('.scrolleffect').on('click', function(event) {
                event.preventDefault();
                app.smoothScroll(this);
            });
        },

       inputMD: function() {
          // trim polyfill : https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/String/Trim
				if (!String.prototype.trim) {
					(function() {
						// Make sure we trim BOM and NBSP
						var rtrim = /^[\s\uFEFF\xA0]+|[\s\uFEFF\xA0]+$/g;
						String.prototype.trim = function() {
							return this.replace(rtrim, '');
						};
					})();
				}

				[].slice.call( document.querySelectorAll( '.fancy-form .form-control' ) ).forEach( function( inputEl ) {
					// in case the input is already filled..
					if( inputEl.value.trim() !== '' ) {
						classie.add( inputEl.parentNode, 'input-filled' );
					}

					// events:
					inputEl.addEventListener( 'focus', onInputFocus );
					inputEl.addEventListener( 'blur', onInputBlur );
				} );

				function onInputFocus( ev ) {
					classie.add( ev.target.parentNode, 'input-filled' );
				}

				function onInputBlur( ev ) {
					if( ev.target.value.trim() === '' ) {
						classie.remove( ev.target.parentNode, 'input-filled' );
					}
				}
        },

      sliderHome: function() {
          $('#slider-home').carousel({
            interval: 10000,
            cycle: true
          });
          $("#slider-home").swiperight(function() {
            $(this).carousel('prev');
          });
          $("#slider-home").swipeleft(function() {
            $(this).carousel('next');
          });
        },

        slickSlider: function() {
            $('.slider-machines').slick({
                slidesToShow: 1,
                slidesToScroll: 1,
                arrows: true,
                fade: true,
                asNavFor: '.slider-nav',
                infinite: false
            });
            $('.slider-nav').slick({
                slidesToShow: 6,
                slidesToScroll: 1,
                asNavFor: '.slider-machines',
                arrows: true,
                focusOnSelect: true,
                centerMode: false,
                responsive: [
                     {
                        breakpoint: 800,
                        settings: {
                          slidesToShow: 5,
                        }
                     },
                     {
                        breakpoint: 600,
                        settings: {
                        slidesToShow: 4,
                        }
                     }
                  ]
            });
            $('.slider-icon').slick({
                slidesToShow: 1,
                slidesToScroll: 1,
                arrows: true,
                fade: true,
                infinite: false
            });
            $('.slider-esg').slick({
              arrows: false,
              autoplay: true,
              infinite: false,
              mobileFirst: true,
              slidesToShow: 1.2,
              responsive: [
                {
                  breakpoint: 575,
                  settings: "unslick"
                }
              ]
          });
          },
        slickResize: function() {
            if($('.icon-info-collapse')) {
                setTimeout(function(){$('.icon-info-collapse').addClass('collapse');$('.icon-info-collapse').removeClass('invisible');},3000);
            }
            //$(window).resize(function() {
                // $(".icon-info-collapse").slick("setPosition");
                // $(".slider-icon").slick("setPosition");
            //});
          },
        homeCollapse: function() {

          var $menu = $('#featured-menu');
            var $link = $menu.find( $('a[data-target]'));
            var $rest = $('.featured-collapse .collapse');

            $link.on('click',function(event){
                event.preventDefault();
            });

          $link.on('mouseenter', function() {

            $this = $(this);
            target = '#' + $this.attr('aria-controls');

            $link.removeClass('active');
            $this.addClass('active');
            $rest.removeClass('active');
            $(target).addClass('active');

          });

            // Código con click
/*            var $myGroup = $('#featured-menu');
            $myGroup.on('show.bs.collapse','.collapse', function() {
                $myGroup.find('.collapse.in').collapse('hide');
            });*/
        },
        maquinasCollapse: function() {
            var $myGroup = $('#pictos');
            $myGroup.on('show.bs.collapse','.collapse', function() {
                $myGroup.find('.collapse.in').collapse('hide');
            });
        },
        parallax: function() {
            if ( $(window).width() > 800 ) {
              var top_header = $('.page-header');
              top_header.css({'background-position':'left center'}); // better use CSS
              $(window).scroll(function () {
                var st = $(this).scrollTop();
                top_header.css({'background-position':'left calc(50% + '+(st*.5)+'px)'});
              });
            }
        },
        cookiesManagement : function () {
            let cookiesModal = $('#cookies-container'),
                cookiesPopup = $('#cookies-popup'),
                cookiesConfigureModal = $('#cookies-configure-container'),
                cookiesConfigure = $('#cookies-configure'),
                btnAccept = cookiesModal.find('#cookie-btn'),
                btnReject = cookiesModal.find('#cookie-btn-reject'),
                btnConfigureLaunch = cookiesModal.find('#cookie-configure'),
                btnCancelConfig = cookiesConfigure.find('.btn-cancel-config'),
                btnSaveConfig = cookiesConfigure.find('.btn-save-config'),
                inputAll = cookiesConfigure.find('#cookies-todas'),
                inputAnalytics = cookiesConfigure.find('#cookies-analiticas'),
                inputMarketing = cookiesConfigure.find('#cookies-marketing'),
                inputRrss = cookiesConfigure.find('#cookies-rrss'),
                configureTextSuccess = cookiesConfigure.find('#text-success'),
                configureTexError = cookiesConfigure.find('#text-error');

            btnAccept.click(function (e){
                e.preventDefault();
                $.cookie(keyAnalitics, '1', { expires: 365, path: '/' });
                $.cookie(keyMarketing, '1', { expires: 365, path: '/' });
                $.cookie(keyRrss, '1', { expires: 365, path: '/' });
                cookiesPopup.slideUp();
                cookiesModal.slideUp();
                location.reload();
            })

            btnReject.click(function (e){
                e.preventDefault();
                $.cookie(keyAnalitics, '0', { expires: 7, path: '/' });
                $.cookie(keyMarketing, '0', { expires: 7, path: '/' });
                $.cookie(keyRrss, '0', { expires: 7, path: '/' });
                cookiesPopup.slideUp();
                cookiesModal.slideUp();
                location.reload();
            })

            btnConfigureLaunch.click(function (e) {
                e.preventDefault();
                cookiesConfigureModal.slideDown();
            })

            btnCancelConfig.click(function (e) {
                e.preventDefault();
                cookiesConfigureModal.slideUp();
            })

            inputAll.click(function (){
                inputAnalytics.prop('checked',$(this).prop('checked'));
                inputMarketing.prop('checked',$(this).prop('checked'));
                inputRrss.prop('checked',$(this).prop('checked'));
            })

            btnSaveConfig.click(function (e){
                e.preventDefault();
                configureTextSuccess.addClass('hidden');
                configureTexError.addClass('hidden');
                $.cookie(keyAnalitics, ((inputAnalytics.prop('checked')) ? 1 : 0), { expires: ((inputAnalytics.prop('checked')) ? 365 : 7), path: '/' });
                $.cookie(keyMarketing, ((inputMarketing.prop('checked')) ? 1 : 0), { expires: ((inputMarketing.prop('checked')) ? 365 : 7), path: '/' });
                $.cookie(keyRrss, ((inputRrss.prop('checked')) ? 1 : 0), { expires: ((inputRrss.prop('checked')) ? 365 : 7), path: '/' });
                cookiesConfigureModal.hide();
                configureTextSuccess.removeClass('hidden');
                cookiesPopup.slideUp();
                cookiesModal.slideUp();
                location.reload();
            })

        }
    };
    // DOM Ready
    $(function(){
        app.init();
    });
    // Resize Window
    $(window).resize( function(){
        app.dropdownMenu();
    });
})(jQuery);